<template>
  <div class="min-w-full p-8">
  <form class="bg-gray-100 dark:bg-gray-900 sm:px-6 text-black dark:text-white min-w-full">
    <div v-if="questionIndex < questions.length" class="grid grid-cols-2">
      <h5 class="col-span-2">{{ question.question }}</h5>
      <template v-for="(c, index) of choices" :key="index">
        <div :id="'choice_'+index" :class='"dark:"+colors[index] +" "+colors[index] + " btn col-span-1 p-2 m-2 text-center"' @click="choiceSelected(c,'choice_'+index)" v-text="c"></div>
      </template>
      <button type="button" @click="submit" :disabled="''===answer" class="btn min-w-full my-8 py-2 col-span-2">Check Answer</button>
    </div>
    <div v-else class="min-w-full">
      <button  class="btn min-w-full my-8 py-2" type="button" @click="restart">restart</button>
    </div>
  </form>
  <p>score: {{ score }}</p>
  </div>
</template>

<script>
import questions from '../json/mn_trivia.js';
const colors = ['bg-amber-600','bg-fuchsia-600','bg-cyan-400','bg-violet-600','bg-red-600'];

export default {
  name: "App",
  data() {
    return {
      questions,
      score: 0,
      questionIndex: 0,
      question: questions[0],
      choices: this.shuffleAnswers(questions[0].choices),
      answer: "",
      colors: colors
    };
  },
  methods: {
    submit() {
      const { answer, question, questions, questionIndex } = this;
      if (answer === question.answer) {
        this.score++;
      }

      if (questionIndex < questions.length) {
        this.questionIndex++;
        this.question = { ...questions[this.questionIndex] };
        this.choices = this.shuffleAnswers(this.question.choices);
      }
      this.removeClassSelected()
    },
    shuffleAnswers(answerArray){
      for (var i = answerArray.length - 1; i > 0; i--) {

        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));

        var temp = answerArray[i];
        answerArray[i] = answerArray[j];
        answerArray[j] = temp;
      }

      return answerArray;
    },
    restart() {
      this.question = questions[0];
      this.answer = "";
      this.questionIndex = 0;
      this.score = 0;
    },
    removeClassSelected(answer=''){
      this.answer = answer;
      const buttons = document.querySelectorAll('.btn');
      buttons.forEach((item)=>{
        item.classList.remove('selected');
      });
      },
    choiceSelected(answer, id){
      this.removeClassSelected(answer);
      document.getElementById(id).classList.add("selected");
    }
  },
};
</script>
