export default [{
  "question": "What is the state bird of Minnesota?",
  "answer": "Common Loon",
  "choices": ["Common Loon", "Bald Eagle", "American Robin", "Red-winged Blackbird"]
}, {
  "question": "Which lake is the largest in Minnesota?",
  "answer": "Lake Superior",
  "choices": ["Lake Superior", "Lake Minnetonka", "Lake of the Woods", "Mille Lacs Lake"]
}, {
  "question": "What is the state flower of Minnesota?",
  "answer": "Pink and White Lady’s Slipper",
  "choices": ["Pink and White Lady’s Slipper", "Wild Rose", "Purple Coneflower", "Showy Milkweed"]
}, {
  "question": "Which city is known as the “Twin Cities” in Minnesota?",
  "answer": "Minneapolis/St. Paul",
  "choices": ["Duluth", "Rochester", "Minneapolis/St. Paul", "St. Cloud"]
}, {
  "question": "What is the state tree of Minnesota?",
  "answer": "Norway Pine",
  "choices": ["Red Pine", "White Oak", "Norway Pine", "Sugar Maple"]
}, {
  "question": "Which river forms part of the border between Minnesota and Wisconsin?",
  "answer": "Mississippi River",
  "choices": ["Mississippi River", "St. Croix River", "Red River", "Minnesota River"]
}, {
  "question": "What is the nickname for the University of Minnesota sports teams?",
  "answer": "Gophers",
  "choices": ["Golden Bears", "Gophers", "Timberwolves", "Vikings"]
}, {
  "question": "Which famous musician was born in Minneapolis, Minnesota?",
  "answer": "Prince",
  "choices": ["Prince", "Bob Dylan", "Judy Garland", "Paul Westerberg"]
}, {
  "question": "What is the state fish of Minnesota?",
  "answer": "Walleye",
  "choices": ["Walleye", "Northern Pike", "Muskellunge", "Smallmouth Bass"]
}, {
  "question": "Which national park is located in northern Minnesota?",
  "answer": "Voyageurs National Park",
  "choices": ["Voyageurs National Park", "Yellowstone National Park", "Grand Canyon National Park", "Great Smoky Mountains National Park"]
}, {
  "question": "What is the official language of Minnesota?",
  "answer": "English",
  "choices": ["English", "Spanish", "Ojibwe", "German"]
}, {
  "question": "Which company has its headquarters in Minneapolis, Minnesota?",
  "answer": "Target Corporation",
  "choices": ["Target Corporation", "Best Buy", "3M", "General Mills"]
}, {
  "question": "What is the state gemstone of Minnesota?",
  "answer": "Lake Superior Agate",
  "choices": ["Lake Superior Agate", "Diamond", "Ruby", "Sapphire"]
}, {
  "question": "Which sport has a strong following in Minnesota due to its cold climate?",
  "answer": "Ice Hockey",
  "choices": ["Ice Hockey", "Baseball", "Surfing", "Tennis"]
}, {
  "question": "What is the state muffin of Minnesota?",
  "answer": "Blueberry Muffin",
  "choices": ["Blueberry Muffin", "Corn Muffin", "Chocolate Chip Muffin", "Bran Muffin"]
}, {
  "question": "Which famous author wrote about life on the prairies of Minnesota in her books?",
  "answer": "Laura Ingalls Wilder",
  "choices": ["Laura Ingalls Wilder", "F. Scott Fitzgerald", "Sinclair Lewis", "Garrison Keillor"]
}, {
  "question": "What is the state butterfly of Minnesota?",
  "answer": "Monarch Butterfly",
  "choices": ["Monarch Butterfly", "Swallowtail Butterfly", "Painted Lady Butterfly", "Viceroy Butterfly"]
}, {
  "question": "Which lake in Minneapolis is known for its scenic beauty and recreational activities?",
  "answer": "Cedar Lake",
  "choices": ["Lake Calhoun", "Lake Harriet", "Lake Nokomis", "Cedar Lake"]
}, {
  "question": "What is the state beverage of Minnesota?",
  "answer": "Grain Belt Beer",
  "choices": ["Milk", "Grain Belt Beer", "Coffee", "Apple Juice"]
}, {
  "question": "Which famous Mayo Clinic is located in which city in Minnesota?",
  "answer": "Rochester",
  "choices": ["Rochester", "Duluth", "St.Paul", "Bloomington"]
}, {
  "question": "What is the state soil of Minnesota?",
  "answer": "Lester Soil",
  "choices": ["Lester Soil", "St.Paul Soil", "Duluth Soil", "Rochester Soil"]
}, {
  "question": "Which famous sculptor created the Spoonbridge and Cherry sculpture in Minneapolis?",
  "answer": "Claes Oldenburg",
  "choices": ["Claes Oldenburg", "Alexander Calder", "Henry Moore", "Louise Nevelson"]
}, {
  "question": "In which year did Minnesota become a state?",
  "answer": "1858",
  "choices": ["1858", "1820", "1900", "1776"]
}, {
  "question": "Which famous shopping mall is located in Bloomington, Minnesota?",
  "answer": "Mall of America",
  "choices": ["Mall of America", "Southdale Center", "Rosedale Center", "Ridgedale Center"]
}, {
  "question": "What is the state mushroom of Minnesota?",
  "answer": "Morel Mushroom",
  "choices": ["Morel Mushroom", "Chanterelle Mushroom", "Porcini Mushroom", "Shiitake Mushroom"]
}, {
  "question": "Which NFL team plays its home games at U.S.Bank Stadium in Minneapolis,Minnesota?",
  "answer": "Minnesota Vikings",
  "choices": ["Minnesota Vikings", "Green Bay Packers", "Chicago Bears", "Detroit Lions"]
}, {
  "question": "What is the state grain of Minnesota?",
  "answer": "Wild Rice",
  "choices": ["Wild Rice", "Corn", "Wheat", "Barley"]
}, {
  "question": "Which famous festival takes place annually in St.Paul,Minnesota celebrating winter sports and activities?",
  "answer": "Winter Carnival",
  "choices": ["Winter Carnival", "Summerfest", "Fall Fiesta", "Spring Fling"]
}, {
  "question": "What is the state fruit of Minnesota?",
  "answer": "Honeycrisp Apple",
  "choices": ["Honeycrisp Apple", "Blueberry", "Strawberry", "Raspberry"]
}, {
  "question": "Which famous architect designed many buildings on the University of Minnesota campus including Northrop Auditorium and Walter Library?",
  "answer": "Cass Gilbert",
  "choices": ["Cass Gilbert", "Frank Lloyd Wright", "Louis Sullivan", "I.M.Pei"]
}, {
  "question": "Which popular amusement park is located in Shakopee,Minnesota?",
  "answer": "Valleyfair",
  "choices": ["Valleyfair", "Nickelodeon Universe", "Mall of America Theme Park", "Canterbury Park"]
}, {
  "question": "What is the state muffin of Minnesota?",
  "answer": "Blueberry Muffin",
  "choices": ["Blueberry Muffin", "Corn Muffin", "Chocolate Chip Muffin", "Bran Muffin"]
}, {
  "question": "Which famous naturalist and author lived and worked at his farm near Ely,Minnesota for over fifty years, writing extensively about nature and conservation issues?",
  "answer": "Sigurd Olson",
  "choices": ["Sigurd Olson", "John Muir", "Aldo Leopold", "Rachel Carson"]
}, {
  "question": "What are residents of Minneapolis called informally due to its cold climate and harsh winters?",
  "answer": "Minneapolitans",
  "choices": ["Minneapolitans", "Minnesotans", "Twin Citians", "Northlanders"]
}, {
  "question": "What are residents of St.Paul informally called due to its cold climate and harsh winters?",
  "answer": "St.Paulites",
  "choices": ["St.Paulites", "Minnesotans", "Twin Citians", "Northlanders"]
}, {
  "question": "Which famous singer-songwriter was born in Hibbing,Minnesota and became known for his influential music career spanning several decades?",
  "answer": "Bob Dylan",
  "choices": ["Bob Dylan", "Bruce Springsteen", "Elton John", "John Lennon"]
}, {
  "question": "What are residents of Duluth informally called due to its location on Lake Superior and its maritime history?",
  "answer": "Duluthians",
  "choices": ["Duluthians", "Minnesotans", "Twin Citians", "Northlanders"]
}, {
  "question": "Which popular ski resort town located along Lake Superior’s North Shore attracts visitors for winter sports and outdoor activities?",
  "answer": "Lutsen Mountains",
  "choices": ["Lutsen Mountains", "Duluth Heights", "Grand Marais", "Two Harbors"]
}
]
